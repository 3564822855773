import { Wrap } from '@chakra-ui/react'
import { useTrail } from 'react-spring'
import { useInView } from 'react-intersection-observer'

import { AnimatedBox, DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'

import Summary, { SummaryProps } from './Summary'

interface Props {
  summaries: SummaryProps[]
  id?: string
}

function SummaryGroup({ summaries, id }: Props): React.ReactElement {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  })

  const springs = useTrail(summaries.length, {
    to: {
      transform: inView ? 'translate3d(0, 0px, 0)' : 'translate3d(0, 32px, 0)',
      opacity: inView ? 1 : 0,
    },
    config: DEFAULT_SPRING_CONFIG,
  })

  return (
    <Wrap as="section" id={id} ref={ref} justify="center" spacing={0}>
      {summaries.map((summary, index) => {
        return (
          <AnimatedBox
            key={`${index}`}
            style={springs[index]}
            sx={{
              py: {
                base: 8,
                md: 14,
              },
              px: {
                base: 0,
                md: 14,
              },
            }}
          >
            <Summary {...summary} />
          </AnimatedBox>
        )
      })}
    </Wrap>
  )
}

export default SummaryGroup
