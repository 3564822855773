import { Text, VStack, Flex, Box, Grid, Button } from '@chakra-ui/react'
import { useTranslation, Trans } from 'next-i18next'
import Image from 'next/image'

import ScrollLink from '@/lib/scroll'
import { logEvent } from '@/lib/gtm'

import { GET_QUOTATION } from '@/routes/landing'

import MagnetButton from '@/components/Buttons/MagnetButton'
import { NAVIGATION_BAR_HEIGHT } from '@/components/NavigationBar/constants'

import MobileBgImage from '@/images/landing/hero-mobile.jpg'
import DesktopBgImage from '@/images/landing/hero.jpg'

function Hero(): React.ReactElement {
  const { t: tLanding } = useTranslation('landing')

  return (
    <Flex
      as="section"
      id="hero"
      sx={{
        w: 'full',
        minH: {
          base: `calc(100vh - ${NAVIGATION_BAR_HEIGHT.base})`,
          md: `calc(100vh - ${NAVIGATION_BAR_HEIGHT.md})`,
        },
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'secondary.800',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative',
        mixBlendMode: 'multiply',
        backgroundSize: {
          base: 'cover',
          md: 'auto 100%',
        },
      }}
    >
      <Box
        layerStyle="fullPageBackgroundImage"
        sx={{
          display: { base: 'block', md: 'none' },
        }}
      >
        <Image
          priority
          src={MobileBgImage}
          alt=""
          layout="fill"
          placeholder="blur"
          objectFit="cover"
          quality="50"
        />
      </Box>
      <Box
        layerStyle="fullPageBackgroundImage"
        sx={{
          display: { base: 'none', md: 'block' },
        }}
      >
        <Image
          priority
          src={DesktopBgImage}
          alt=""
          layout="fill"
          placeholder="blur"
          objectFit="cover"
          quality="90"
        />
      </Box>
      <Grid
        layerStyle="container"
        sx={{
          gridTemplateColumns: {
            base: '1fr',
            md: '5fr 4fr',
          },
          gridGap: {
            base: 6,
            md: 0,
          },
          alignItems: 'center',
        }}
      >
        <VStack
          spacing="4"
          sx={{
            fontWeight: 'medium',
            alignItems: {
              base: 'center',
              md: 'flex-start',
            },
            color: 'white',
            textAlign: {
              base: 'center',
              md: 'left',
            },
          }}
        >
          <Text
            as="h1"
            sx={{
              fontSize: {
                base: '3.5xl',
                sm: '5xl',
                md: '5.5xl',
              },
              whiteSpace: {
                base: 'unset',
                md: 'pre',
              },
              lineHeight: 1.2,
              '& > br': {
                display: {
                  base: 'none',
                  md: 'block',
                },
              },
            }}
          >
            <Trans t={tLanding} i18nKey={'hero.title'}>
              ให้คำปรึกษา พร้อมจัดทำ <br />
              PDPA ครบวงจร
            </Trans>
          </Text>
          <Text
            as="h2"
            sx={{
              fontSize: {
                base: 'xl',
                sm: '2xl',
              },
              fontWeight: 'normal',
            }}
          >
            {tLanding('hero.subtitle')}
          </Text>
          <Box
            sx={{
              pt: {
                base: 8,
                sm: 12,
              },
            }}
          >
            <MagnetButton
              as={ScrollLink}
              url={GET_QUOTATION}
              size="xl"
              onClick={() => {
                logEvent({
                  ga: {
                    category: 'CTA',
                    action: 'Click Get Quote',
                    label: 'Home Banner',
                  },
                })
              }}
              sx={{
                cursor: 'pointer',
                minW: '263px',
              }}
              containerSX={{
                display: {
                  base: 'none',
                  md: 'block',
                },
              }}
            >
              {tLanding('hero.cta')}
            </MagnetButton>
            <Button
              as={ScrollLink}
              url={GET_QUOTATION}
              size="xl"
              onClick={() => {
                logEvent({
                  ga: {
                    category: 'CTA',
                    action: 'Click Get Quote',
                    label: 'Home Banner',
                  },
                })
              }}
              sx={{
                cursor: 'pointer',
                minW: '263px',
                display: {
                  base: 'flex',
                  md: 'none',
                },
              }}
            >
              {tLanding('hero.cta')}
            </Button>
          </Box>
        </VStack>
      </Grid>
    </Flex>
  )
}

export default Hero
