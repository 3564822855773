import { Text, VStack } from '@chakra-ui/react'

export interface Props {
  heading: React.ReactNode
  paragraph: React.ReactNode
  id?: string
}

function Paragraph({ id, heading, paragraph }: Props): React.ReactElement {
  return (
    <VStack
      id={id}
      sx={{
        alignItems: {
          base: 'center',
          md: 'flex-start',
        },
        textAlign: {
          base: 'center',
          md: 'left',
        },
      }}
    >
      <Text
        sx={{
          fontSize: {
            base: 'xl',
            sm: '2xl',
          },
          fontWeight: 'medium',
        }}
      >
        {heading}
      </Text>
      <Text sx={{ fontSize: 'lg' }}>{paragraph}</Text>
    </VStack>
  )
}

export default Paragraph
