import { Text, VStack, Box, Stack } from '@chakra-ui/react'

export type ParagraphProps = {
  setCarouselIndex?: (index: number) => void
}

export interface SummaryProps {
  title: React.ReactNode
  Paragraph: React.FC<ParagraphProps>
  image: React.ReactNode
  id?: string
}

function Summary({
  title,
  Paragraph,
  image,
  id,
}: SummaryProps): React.ReactElement {
  return (
    <Stack
      as="article"
      id={id}
      spacing={{
        base: 2,
        sm: 6,
        md: 8,
      }}
      direction={{
        base: 'column',
        sm: 'row',
        md: 'column',
      }}
      sx={{
        alignItems: 'center',
        maxW: {
          md: '400px',
        },
      }}
    >
      <Box
        sx={{
          flex: '0 0 fit-content',
          minH: {
            base: 20,
            sm: 20,
            md: 28,
          },
          minW: {
            base: 20,
            sm: 20,
            md: 28,
          },
        }}
      >
        {image}
      </Box>
      <VStack
        spacing="1"
        sx={{
          alignItems: {
            base: 'center',
            sm: 'flex-start',
            md: 'center',
          },
          textAlign: {
            base: 'center',
            sm: 'left',
            md: 'center',
          },
        }}
      >
        <Text
          as="h2"
          sx={{ fontWeight: 'medium', fontSize: { base: 'xl', sm: '2xl' } }}
        >
          {title}
        </Text>
        <Paragraph />
      </VStack>
    </Stack>
  )
}

export default Summary
