import { Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

interface Props {
  id?: string
}

function Paragraph({ id }: Props): React.ReactElement {
  const { t } = useTranslation('landing')

  return (
    <VStack
      id={id}
      spacing="-8px"
      sx={{
        alignItems: {
          base: 'center',
          md: 'flex-start',
        },
        fontSize: {
          base: '3xl',
          sm: '4xl',
        },
      }}
    >
      <Text
        sx={{
          fontWeight: 'medium',
        }}
      >
        {t('whyUs.title')}
      </Text>
    </VStack>
  )
}

export default Paragraph
