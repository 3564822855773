import { useRef } from 'react'
import { Box, ButtonProps, CSSObject } from '@chakra-ui/react'
import { useSpring, config } from 'react-spring'
import { useMove } from 'react-use-gesture'

import { ScrollLinkProps } from '@/lib/scroll'

import { AnimatedButton } from '@/lib/springComponent'

type Props = {
  AOI?: Record<string, string>
  debug?: boolean
  containerSX?: CSSObject
  to?: string
} & Partial<ButtonProps> &
  Partial<ScrollLinkProps>

const defaultTransformValue =
  'translate3d(calc(var(--AOI-SIZE) * 0), calc(var(--AOI-SIZE) * 0), 0)'

function MagnetButton({
  AOI = {
    base: '8px',
    sm: '12px',
  },
  debug,
  containerSX,
  sx = {},
  ...rest
}: Props): React.ReactElement {
  const moveareaRef = useRef<HTMLDivElement>(null)
  const [spring, springApi] = useSpring(() => ({
    transform: defaultTransformValue,
    config: config.gentle,
  }))

  const bind = useMove(({ xy: [mx, my], moving }) => {
    if (moving && moveareaRef.current) {
      const { x, y, width, height } =
        moveareaRef.current.getBoundingClientRect()

      const percentX = ((mx - x) / width - 0.5) * 2
      const percentY = ((my - y) / height - 0.5) * 2

      springApi.start({
        transform: `translate3d(calc(var(--AOI-SIZE) * ${percentX}), calc(var(--AOI-SIZE) * ${percentY}), 0)`,
      })
    }
  })

  return (
    <Box sx={containerSX}>
      <Box
        {...bind()}
        onMouseLeave={() => {
          springApi.start({
            transform: defaultTransformValue,
          })
        }}
        ref={moveareaRef}
        sx={{
          '--AOI-SIZE': AOI,
          width: 'calc(fit-content + calc(var(--AOI-SIZE) * 2))',
          marginLeft: 'calc(-1 * var(--AOI-SIZE))',
          marginTop: 'calc(-1 * var(--AOI-SIZE))',
          padding: 'var(--AOI-SIZE)',
          bg: debug ? 'red' : undefined,
        }}
      >
        <AnimatedButton {...rest} style={{ ...spring }} sx={sx} />
      </Box>
    </Box>
  )
}

export default MagnetButton
