import { Fragment } from 'react'

import NavigationBar from '@/components/NavigationBar'
import Footer from '@/components/Footer'
import { AlternateLink } from '@/components/AlternateLink'

import Hero from '@/features/landing/templates/Hero'
import OurServices from '@/features/landing/templates/OurServices'
import WhyUs from '@/features/landing/templates/WhyUs'
import OurTrustingClient from '@/features/landing/templates/OurTrustingClient'
import Carousel from '@/features/landing/templates/Carousel'
import Form from '@/features/landing/templates/Form'
import FAQs from '@/features/landing/templates/FAQs'
import Blogs from '@/features/landing/templates/Blogs'
import Contact from '@/features/landing/templates/Contact'
import ServiceMethodology from '@/features/landing/templates/ServiceMethodology'
import { HandoutBanner } from '@/features/resources/components/Banner'

import { useFAQsData } from '@/features/landing/constants/FAQs'

import { BlogListItem } from '@/features/blogs/types/blog'
import OurTeam from '../../templates/OurTeam'

export interface LandingPageProps {
  blogs: BlogListItem[] | null
}

function LandingPage({ blogs }: LandingPageProps): React.ReactElement {
  const FAQS = useFAQsData()

  return (
    <Fragment>
      <AlternateLink />
      <NavigationBar />
      <Hero />
      <OurServices />
      <WhyUs />
      <OurTrustingClient />
      <ServiceMethodology />
      <OurTeam />
      <Carousel />
      <HandoutBanner />
      <Form />
      <FAQs items={FAQS} />
      {blogs && <Blogs blogs={blogs} />}
      <Contact />
      <Footer />
    </Fragment>
  )
}

export default LandingPage
