import { Grid, VStack, Box, AspectRatio } from '@chakra-ui/react'
import Image from 'next/image'
import { useTrail } from 'react-spring'
import { useInView } from 'react-intersection-observer'

import { AnimatedBox, DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'

import { Heading, Paragraph } from '@/features/landing/components/WhyUs'
import { useWhyUsData } from '@/features/landing/constants/whyUs'

import WhyUsImage from '@/images/landing/whyus.png'

function WhyUs(): React.ReactElement {
  const WHY_US = useWhyUsData()

  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  })

  const springs = useTrail(WHY_US.length, {
    to: {
      transform: inView ? 'translate3d(0, 0px, 0)' : 'translate3d(0, 56px, 0)',
      opacity: inView ? 1 : 0,
    },
    config: DEFAULT_SPRING_CONFIG,
  })

  return (
    <Box
      as="section"
      id="why-us"
      ref={ref}
      sx={{
        w: 'full',
      }}
    >
      <Grid
        layerStyle="container"
        sx={{
          gridTemplateColumns: {
            base: '1fr',
            md: '1fr 1fr',
          },
          gridGap: {
            base: 6,
            md: 0,
          },
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: 'gray.300',
        }}
      >
        <AspectRatio
          ratio={633 / 725}
          sx={{
            mr: {
              base: 10,
              md: 20,
            },
            ml: {
              base: 10,
              md: 0,
            },
          }}
        >
          <Image
            src={WhyUsImage}
            alt="Why Us"
            layout="fill"
            objectFit="contain"
            sizes="50%"
            placeholder="blur"
          />
        </AspectRatio>
        <VStack
          spacing={{
            base: 6,
            md: 8,
          }}
          layerStyle="landingSectionHeading"
          sx={{
            alignItems: 'stretch',
          }}
        >
          <Heading />
          {WHY_US.map((datum, index) => {
            return (
              <AnimatedBox key={`${index}`} style={springs[index]}>
                <Paragraph {...datum} />
              </AnimatedBox>
            )
          })}
        </VStack>
      </Grid>
    </Box>
  )
}

export default WhyUs
