import { Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import SummaryGroup from '@/features/landing/components/OurServices'
import { useServiceData } from '@/features/landing/constants/ourServices'

function OurServices(): React.ReactElement {
  const SUMMARIES = useServiceData()

  const { t } = useTranslation('landing')

  return (
    <VStack
      spacing={{
        base: 4,
        md: 8,
      }}
      id="our-services"
      as="section"
      layerStyle="container"
      sx={{
        borderBottom: '1px solid',
        borderColor: 'gray.300',
      }}
    >
      <VStack
        spacing="1"
        layerStyle="landingSectionHeading"
        sx={{
          alignItems: 'center',
        }}
      >
        <Text as="h1" textStyle="landingSectionTitle">
          {t('ourService.title')}
        </Text>
      </VStack>
      <SummaryGroup summaries={SUMMARIES} />
    </VStack>
  )
}

export default OurServices
