import { GetStaticPropsContext, GetStaticPropsResult } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import LandingPage, { LandingPageProps } from '@/features/landing/pages/Landing'
import { getBlogs } from '@/features/blogs/api/getBlogs'

import { DEFAULT_LOCALE } from '@/lib/localization/constants'

import { REVALIDATE_TIMEOUT } from '@/constants/page'

export async function getStaticProps(
  ctx: GetStaticPropsContext,
): Promise<GetStaticPropsResult<LandingPageProps>> {
  const { locale = DEFAULT_LOCALE } = ctx

  const { blogs } = await getBlogs(
    locale,
    {
      page: 0,
      per: 4,
    },
    4,
  )

  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'common',
        'landing',
        'handout',
        'faqs',
      ])),
      blogs,
    },
    revalidate: REVALIDATE_TIMEOUT,
  }
}

export default LandingPage
