import { useMemo } from 'react'
import { useTranslation } from 'next-i18next'

import { ParagraphProps } from '@/features/landing/components/WhyUs'

export const useWhyUsData = (): ParagraphProps[] => {
  const {
    t,
    i18n: { language },
  } = useTranslation('landing')

  return useMemo(
    () =>
      new Array(3).fill(0).map((_, index) => {
        return {
          heading: t(`whyUs.contents.${index}.heading`),
          paragraph: t(`whyUs.contents.${index}.paragraph`),
        }
      }),
    [language],
  )
}
