import { useMemo } from 'react'
import { Text, Link } from '@chakra-ui/react'
import { useTranslation, Trans } from 'next-i18next'

import {
  IMPLEMENTATION,
  ADVISORY,
  TRAINING,
  COMPLIANCE,
  LEGAL,
} from '@/routes/services'

import { SummaryProps } from '@/features/landing/components/OurServices/Summary'

import { ReactComponent as ImageImple } from '@/svg/landing/sc3_implementation.svg'
import { ReactComponent as ImageAdvis } from '@/svg/landing/sc3_advisory.svg'
import { ReactComponent as ImageTraining } from '@/svg/landing/sc3_training.svg'
import { ReactComponent as ImageCompliance } from '@/svg/landing/sc3_compliance.svg'
import { ReactComponent as ImageLegal } from '@/svg/landing/sc3_legal.svg'

import ScrollLink from '@/lib/scroll'

const paragraphStyle = {
  fontSize: 'lg',
}

const strongStyle = {
  fontWeight: 'medium',
}

const linkStyle = {
  cursor: 'pointer',
  fontWeight: 'medium',
  textDecoration: 'underline !important',
}

export const useServiceData = (): SummaryProps[] => {
  const {
    t,
    i18n: { language },
  } = useTranslation('landing')

  return useMemo(
    () => [
      {
        title: t('ourService.implementation.title'),
        image: <ImageImple />,
        Paragraph: (): React.ReactElement => (
          <Text sx={paragraphStyle}>
            <Trans t={t} i18nKey="ourService.implementation.paragraph">
              ตรวจสอบการจัดการข้อมูลส่วนบุคคล จัดทำ
              <Text as="strong" sx={strongStyle}>
                Gap&nbsp;Analysis
              </Text>
              ปรับกระบวนการทำงานพร้อมจัดทำเอกสารทางกฎหมาย
              โดยทีมนักกฎหมายเชี่ยวชาญ
              <Link as={ScrollLink} url={IMPLEMENTATION} sx={linkStyle}>
                {t('ourService.link')}
              </Link>
            </Trans>
          </Text>
        ),
      },
      {
        title: t('ourService.advisory.title'),
        image: <ImageAdvis />,
        Paragraph: (): React.ReactElement => (
          <Text sx={paragraphStyle}>
            <Trans t={t} i18nKey="ourService.advisory.paragraph">
              ปรึกษา PDPA กับนักกฎหมายผู้เชี่ยวชาญ
              <Text as="strong" sx={strongStyle}>
                แบบส่วนตัว (Private Session)
              </Text>
              ครอบคลุม ทุกรายละเอียดของการปฏิบัติให้ถูกต้อง ตาม PDPA
              สำหรับธุรกิจของคุณ
              <Link as={ScrollLink} url={ADVISORY} sx={linkStyle}>
                {t('ourService.link')}
              </Link>
            </Trans>
          </Text>
        ),
      },
      {
        title: t('ourService.training.title'),
        image: <ImageTraining />,
        Paragraph: (): React.ReactElement => (
          <Text sx={paragraphStyle}>
            <Trans t={t} i18nKey="ourService.training.paragraph">
              จัดอบรบ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล
              ให้กับบุคคลากรภายในองค์กรเพื่อสร้าง ความตระหนักรู้
              รวมถึงแนวทางปฏิบัติ PDPA พร้อมตอบทุกข้อสงสัย
              <Link as={ScrollLink} url={TRAINING} sx={linkStyle}>
                {t('ourService.link')}
              </Link>
            </Trans>
          </Text>
        ),
      },
      {
        title: t('ourService.compliance.title'),
        image: <ImageCompliance />,
        Paragraph: (): React.ReactElement => (
          <Text sx={paragraphStyle}>
            <Trans t={t} i18nKey="ourService.compliance.paragraph">
              ตรวจสอบการปฏิบัติตาม PDPA ขององค์กร ประเมินความเสี่ยง จัดทำรายงาน
              Compliance Audit Report และให้คำแนะนำโดยทีมนักกฎหมาย
              <Link as={ScrollLink} url={COMPLIANCE} sx={linkStyle}>
                {t('ourService.link')}
              </Link>
            </Trans>
          </Text>
        ),
      },
      {
        title: t('ourService.legal.title'),
        image: <ImageLegal />,
        Paragraph: (): React.ReactElement => (
          <Text sx={paragraphStyle}>
            <Trans t={t} i18nKey="ourService.legal.paragraph">
              บริการจัดทำเอกสารทางกฎหมายโดยนักกฎหมายเชี่ยวชาญ PDPA
              <Link as={ScrollLink} url={LEGAL} sx={linkStyle}>
                {t('ourService.link')}
              </Link>
            </Trans>
          </Text>
        ),
      },
    ],
    [language],
  )
}
