import { Text, VStack, Box, Grid } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import { Content, Heading, Image } from '@/features/landing/components/OurTeam'

import legalImage from '@/images/landing/chayanan.jpg'

function OurTeam(): React.ReactElement {
  const { t } = useTranslation('landing')

  return (
    <Box
      id="our-team"
      as="section"
      sx={{
        w: 'full',
        overflow: 'hidden',
      }}
    >
      <Grid
        layerStyle="container"
        sx={{
          gridTemplateColumns: {
            base: '1fr',
            md: '1fr 1fr',
          },
          alignItems: 'center',
          gridGap: {
            base: 4,
            md: 6,
          },
        }}
      >
        <VStack
          spacing="4"
          sx={{
            alignItems: 'center',
            gridColumn: { base: 1, md: 2 },
          }}
        >
          <Text as="h1" textStyle="landingSectionTitle">
            {t('ourTeam.title')}
          </Text>
          <Heading />
          <Content isAlternativeStyle hideCertificates={true} />
        </VStack>
        <Image
          image={legalImage}
          isAlternativeStyle
          ratio={376 / 484}
          sx={{
            mx: 12,
            gridRow: 1,
            gridColumn: 1,
          }}
        />
      </Grid>
    </Box>
  )
}

export default OurTeam
